import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { showToast } from '@/lib/toast';
import { useRouter } from 'next/compat/router';
import { useCallback, useMemo, useState } from 'react';
import { getDefaultLeadMessage } from '../lib/getDefaultLeadMessage';
import { getPublicIpAddress } from '../lib/getPublicIpAddress';
import { DealerType } from '../lib/schema/dealer/types';
import { JDPowerIncentive } from '../lib/schema/incentives/types';
import {
  VehicleListingType,
  VehicleType,
  VehicleTypeWithTrimSpecs,
} from '../lib/schema/inventory/types';
import { CognitoUser } from '../lib/schema/user/types';
import { ListingEmailInfo, MinimalGenerateLeadRequest } from '../types/leads';

interface DealerMessageBodyType {
  product: VehicleTypeWithTrimSpecs | VehicleListingType | VehicleType;
  dealer: DealerType | null;
  user?: CognitoUser | null;
  localRebates: JDPowerIncentive[];
  stateRebates: JDPowerIncentive[];
  estimatedFuelSavingsPerYear?: number;
  body?: MinimalGenerateLeadRequest;
}

const createDealerMessageBody = async ({
  product,
  dealer,
  user,
  localRebates = [],
  stateRebates = [],
  estimatedFuelSavingsPerYear = 0,
  body,
}: DealerMessageBodyType) => {
  const ipAddress = await getPublicIpAddress();

  let drivetrain = 'N/A';
  if (product.drivetrain) {
    drivetrain = product.drivetrain;
  } else if (
    'trimSpecs' in product &&
    product.trimSpecs.highlights.Drive_Type
  ) {
    drivetrain = product.trimSpecs.highlights.Drive_Type;
  }
  const federalTaxCredit = product.calculatedIncentives?.Federal || 0;

  const parsedLocalAndStateIncentives: { name: string; value: number }[] = [];
  const pointOfSaleRebates: { name: string; value: number }[] = [];
  const afterwardsRebates: { name: string; value: number }[] = [];
  const rebates = [...localRebates, ...stateRebates];
  rebates.forEach((incentive) => {
    if (incentive.max_amount && incentive.max_amount > 0) {
      const reducedIncentive = {
        name: incentive.name,
        value: incentive.max_amount || 0,
      };
      if (incentive.point_of_sale) {
        pointOfSaleRebates.push(reducedIncentive);
      } else {
        afterwardsRebates.push(reducedIncentive);
      }
      parsedLocalAndStateIncentives.push(reducedIncentive);
    }
  });

  const totalSavings =
    parsedLocalAndStateIncentives.reduce((acc, incentive) => {
      return acc + incentive.value;
    }, 0) +
    estimatedFuelSavingsPerYear +
    federalTaxCredit;

  const baseMsrp = product.price.value
    ? product.price.value
    : 'Contact dealer for price';
  const totalMsrp = product.price.value
    ? product.price.value - totalSavings
    : 'Contact dealer for price';

  const messageBody: ListingEmailInfo = {
    ...body,
    full_name: user?.fullName,
    phone: body?.phone ?? '',
    customer_email: body?.customer_email || user?.email,
    message:
      body?.message ||
      `Hi, is the ${product.year} ${product.make} ${product.model} still available?`,
    listing_url: window.location.href,
    listing_id: product.listingId,
    stock_number: product?.stockNumber,
    // additional info required for customer email
    additional_info: {
      dealership: dealer?.name,
      dealership_address: dealer?.address,
      dealership_postal_code: dealer?.postalCode,
      dealership_city: dealer?.city,
      dealership_province: dealer?.province,
      vin: product?.vin,
      listing_id: product?.listingId,
      stockNumber: product?.stockNumber,
      modelImgUrl: product.images[0],
      year: product.year,
      make: product.make,
      model: product.model,
      trim: product.trim,
      base_msrp: baseMsrp,
      total_savings: totalSavings,
      total_msrp: totalMsrp,
      mileage: product.mileage,
      range: product.rangeInfo,
      ip_address: ipAddress,
      fuel_type: product.fuelType || 'N/A',
      drivetrain,
      federal_tax_credit: federalTaxCredit,
      point_of_sale_rebates: pointOfSaleRebates || [],
      afterwards_rebates: afterwardsRebates || [],
      est_fuel_savings: estimatedFuelSavingsPerYear || 0,
    },
  };
  return messageBody;
};

export const useContactDealer = ({
  product,
  dealer,
  user,
  incentivesData,
  estimatedFuelSavingsPerYear,
}: {
  product: VehicleType | VehicleListingType;
  dealer: DealerType | null;
  user: CognitoUser | null;
  incentivesData?: {
    local: JDPowerIncentive[];
    state: JDPowerIncentive[];
  };
  estimatedFuelSavingsPerYear: number;
}) => {
  const router = useRouter();
  const { handleActionTracking } = useAnalytics();

  const getLeadRequest = () => {
    let currentLeadRequests: Record<string, MinimalGenerateLeadRequest> = {};

    try {
      currentLeadRequests = JSON.parse(
        sessionStorage.getItem('leadRequests') || '{}'
      );
    } catch (error) {
      sessionStorage.removeItem('leadRequests');
    }

    if (currentLeadRequests[product.listingId]) {
      return currentLeadRequests[product.listingId];
    }

    if (user) {
      return {
        message: '',
        full_name: user.fullName,
        customer_email: user.email,
        phone: user.phone,
        availableTestDriveDates: undefined,
      };
    }

    return {
      message: undefined,
      full_name: undefined,
      customer_email: undefined,
      phone: undefined,
      availableTestDriveDates: undefined,
    };
  };

  const [leadRequest, setLeadRequest] =
    useState<MinimalGenerateLeadRequest>(getLeadRequest());

  const handleSetLeadRequest = (message: MinimalGenerateLeadRequest) => {
    const currentLeadRequests = JSON.parse(
      sessionStorage.getItem('leadRequests') || '{}'
    );
    const newLeadRequests = {
      ...currentLeadRequests,
      [product.listingId]: message,
    };
    sessionStorage.setItem('leadRequests', JSON.stringify(newLeadRequests));

    setLeadRequest(message);
  };

  const baseMessageBody: DealerMessageBodyType = useMemo(
    () => ({
      product,
      dealer,
      user,
      localRebates: incentivesData?.local || [],
      stateRebates: incentivesData?.state || [],
      estimatedFuelSavingsPerYear,
    }),
    [product, dealer, user, incentivesData, estimatedFuelSavingsPerYear]
  );

  const contactDealer = async (body: ListingEmailInfo): Promise<Response> => {
    const response = await fetch('/api/message_dealer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return response;
  };

  const messageDealer = useCallback(
    async ({ onSuccess }: { onSuccess?: () => Promise<void> }) => {
      const bookATestDrive =
        leadRequest.availableTestDriveDates &&
        leadRequest.availableTestDriveDates.length > 0;

      const formattedMessage = `${
        leadRequest.message ||
        getDefaultLeadMessage({ name: user?.fullName, ...product })
      }${bookATestDrive ? '\n\nCan I book a test drive?' : ''}${leadRequest.availableTestDriveDates && bookATestDrive ? ` I'm available on these days: ${leadRequest.availableTestDriveDates}` : ''}`;

      const newBody = await createDealerMessageBody({
        ...baseMessageBody,
        body: { ...leadRequest, message: formattedMessage },
      });

      handleActionTracking(GOOGLE_TRACK_INFO.sendMessageButton);

      const response = await contactDealer(newBody);
      if (response.ok) {
        showToast('Sent! the dealer will be in touch soon.', {
          type: 'success',
          onClick: () => router?.push('/my-evs'),
        });
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageSuccess);
        if (onSuccess) {
          await onSuccess();
        }
      } else {
        showToast(
          'There was a problem contacting the dealer. Please try again.',
          { type: 'error' }
        );
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageFail);
      }
    },
    [router, handleActionTracking, baseMessageBody, leadRequest, user, product]
  );

  return { contactDealer: messageDealer, leadRequest, handleSetLeadRequest };
};
